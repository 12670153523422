// Import the necessary dependencies
import React from "react";

// Define the Home component
function Content2() {
  return (
    <div id="PC" className="text-justify">
      <section>
        <div className="pr-8 pl-8 pt-8 pb-2 rounded-2xl">
          <h1 className="text-center">
            <span className=" text-[#132043] text-2xl font-bold ">
              Process Compliance
            </span>
          </h1>
          <h1 className="text-lg mb-4 text-[#132043] ">
            SGES provides comprehensive process compliance services to navigate
            industry standards and regulatory requirements, ensuring product
            safety and reliability. Our expertise includes:
          </h1>
          <div className="flex flex-col-reverse md:flex-row gap-8 justify-center items-stretch">
            {/* Box 1 */}
            <div className="flex-1 max-w-xs w-full mx-auto rounded overflow-hidden shadow-lg flex flex-col bg-[#CACFD6]">
              <div className="px-6 py-4 flex-grow">
                <div className="font-bold text-xl mb-2 text-[#132043]">
                  Aerospace and Defense:
                </div>
                <p className="text-base pt-2">DO-178C, DO-254</p>
                <p className="text-base">MIL-STD-1472, MIL-STD-882</p>
              </div>
            </div>
            {/* Box 2 */}
            <div className="flex-1 max-w-xs w-full mx-auto rounded overflow-hidden shadow-lg flex flex-col bg-[#CACFD6]">
              <div className="px-6 py-4 flex-grow">
                <div className="font-bold text-xl mb-2 text-[#132043]">
                  Medical Devices:
                </div>
                <p className="text-base pb-2 pt-2">IEC 62304</p>
              </div>
            </div>
            {/* Box 3 */}
            <div className="flex-1 max-w-xs w-full mx-auto rounded overflow-hidden shadow-lg flex flex-col bg-[#CACFD6]">
              <div className="px-6 py-4 flex-grow">
                <div className="font-bold text-xl mb-2 text-[#132043]">
                  Printed Circuit Boards:
                </div>
                <p className="text-base pt-2">IPC-2221</p>
              </div>
            </div>
          </div>
          <h1 className="text-lg mt-4 text-[#132043]">
            We have a proven track record in aerospace, defense, and medical
            industries, where safety and reliability are essential.
          </h1>
        </div>
      </section>
    </div>
  );
}

export default Content2;
