// Import the necessary dependencies
import React from "react";
import img from "../../Assets/images/Picture5.jpeg";
// Define the Home component
function Content1() {
  return (
    <div id="H">
      <section>
        <div className="p-8 bg-[#CACFD6] rounded-2xl">
          <div className="flex flex-col-reverse md:flex-row mt-4">
            <div className="text-left">
              <h1 className="text-4xl font-bold mb-4 text-[#132043] lg:pt-6">
                Embedded Systems design
              </h1>

              <p className="mb-4 md:mb-0 md:mr-4 text-xl lg:pr-44 lg:pt-6">
                At <b>Silicon Glen Embedded Systems (SGES)</b>, we know that
                robust hardware is essential for a successful product. Our
                experienced engineers specialize in designing and developing
                advanced hardware solutions tailored to your specific needs.
                With expertise in analog, digital, and mixed-signal electronics,
                we deliver hardware that meets the highest standards of
                performance, reliability, and efficiency.
              </p>
            </div>
            <img
              src={img}
              alt="Picture 1"
              className="w-full md:w-1/3 mb-4 md:mb-0 rounded-2xl h-80 object-cover"
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Content1;
