import React from "react";

// Define the Content1 component
function Content1() {
  return (
    <div className="text-justify">


      <section>
        <div className="p-8 bg-[#CACFD6] rounded-2xl">
          <h1 className="text-2xl font-bold mb-4 text-[#132043] ">
            To enhance our systems engineering capabilities, we leverage
            advanced methodologies such as:
          </h1>
          <div className="flex flex-col-reverse md:flex-row gap-20 justify-center items-stretch">
            <div className="flex-1 max-w-sm rounded overflow-hidden shadow-lg  flex flex-col">
              <div className="px-6 py-4 flex-grow">
                <div className="font-bold text-xl mb-2 text-[#132043]">
                  Model-Based Systems Engineering
                </div>
                <p className="text text-base pb-2 pt-2">
                  Utilizing SysML to create visual models that facilitate
                  communication, analysis, and decision-making throughout the
                  development lifecycle.
                </p>
              </div>
            </div>
            <div className="flex-1 max-w-sm rounded overflow-hidden shadow-lg  flex flex-col">
              <div className="px-6 py-4 flex-grow">
                <div className="font-bold text-xl mb-2 text-[#132043] ">
                  Business Process Modeling
                </div>
                <p className=" text-base pb-2 pt-2">
                  Aligning system development with organizational goals and
                  optimizing workflows for efficiency.
                </p>
              </div>
            </div>
            <div className="flex-1 max-w-sm rounded overflow-hidden shadow-lg flex flex-col">
              <div className="px-6 py-4 flex-grow">
                <div className="font-bold text-xl mb-2 text-[#132043]">
                  Design Structure Matrix
                </div>
                <p className=" text-base pt-2">
                  Identifying dependencies and interactions between system
                  components to optimize design and integration.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Content1;
