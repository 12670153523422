import React, { useRef } from "react";
import logo from "../../Assets/images/Picture16.jpeg"; // Importing the image
import emailjs from "@emailjs/browser";

const Content6 = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_h0ar1se", "template_5dh624u", form.current, {
        publicKey: "zml5zkxWyCkEeBC26",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          alert(
            "Thank you for contacting us. Your Email sent successfully. We will get bakc to you as soon as possible."
          );
        },
        (error) => {
          console.log("FAILED...", error.text);
          alert(
            "Thank you for contacting us. Your Email is not sent. Kindly try again.",
            error.text
          );
        }
      );
  };

  return (
    <div
      id="Contact"
      className="relative overflow-hidden rounded-lg w-full h-full"
    >
      {/* Background Image with Opacity */}
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage: `url(${logo})`,
          opacity: 0.5, // Set background image opacity
          zIndex: -1, // Ensure it's behind the content
        }}
      ></div>

      <div>
        <h1 className="text-2xl font-bold mb-4 c text-[#132043] pt-8 p-8 text-center">
          <span className="bg-[#CACFD6] rounded-2xl p-2">Get in touch</span>
        </h1>
      </div>

      {/* Content */}
      <div className="p-8 rounded-2xl bg-[#CACFD6] text-white z-10 lg:mx-40 md:mx-20 sm:mx-8">
        <section>
          <form ref={form} onSubmit={sendEmail} className="space-y-4">
            {/* Row 1: Full Name, Email, Phone */}
            <div className="flex flex-wrap gap-4">
              <div className="flex-1">
                <label className="text-lg font-bold text-[#132043]">
                  Full Name
                </label>
                <input
                  type="text"
                  name="full_name"
                  required
                  className="w-full p-2 rounded bg-white text-[#132043]"
                />
              </div>
              <div className="flex-1">
                <label className="text-lg font-bold text-[#132043]">
                  Email
                </label>
                <input
                  type="email"
                  name="email_id"
                  className="w-full p-2 rounded bg-white  text-[#132043]"
                  required
                />
              </div>
              <div className="flex-1">
                <label className="text-lg font-bold text-[#132043]">
                  Phone
                </label>
                <input
                  type="tel"
                  name="phone"
                  className="w-full p-2 rounded bg-white  text-[#132043]"
                  required
                />
              </div>
            </div>

            {/* Row 2: Company, Country */}
            <div className="flex flex-wrap gap-4">
              <div className="flex-1">
                <label className="text-lg font-bold text-[#132043]">
                  Company
                </label>
                <input
                  type="text"
                  name="company"
                  className="w-full p-2 rounded bg-white  text-[#132043]"
                  required
                />
              </div>
              <div className="flex-1">
                <label className="text-lg font-bold text-[#132043]">
                  Country
                </label>
                <input
                  type="text"
                  name="country"
                  className="w-full p-2 rounded bg-white  text-[#132043]"
                  required
                />
              </div>
            </div>

            {/* Row 3: Services */}
            <div>
              <label className="text-lg font-bold text-[#132043]">
                What services are you interested in?
              </label>
              <input
                type="text"
                name="services"
                className="w-full p-2 rounded bg-white  text-[#132043]"
                required
              />
            </div>

            {/* Row 4: How did you hear about us? */}
            <div>
              <label className="text-lg font-bold text-[#132043]">
                How did you hear about us?
              </label>
              <input
                type="text"
                name="mode"
                className="w-full p-2 rounded bg-white  text-[#132043]"
                required
              />
            </div>

            {/* Row 5: Message */}
            <div>
              <label className="text-lg font-bold text-[#132043]">
                Message
              </label>
              <textarea
                name="message"
                className="w-full p-2 rounded bg-white  text-[#132043]"
                rows="4"
                required
              ></textarea>
            </div>

            {/* Row 6: Budget */}
            <div>
              <label className="text-lg font-bold text-[#132043]">
                Your approximate budget for this project
              </label>
              <input
                type="text"
                name="budget"
                className="w-full p-2 rounded bg-white   text-[#132043]"
                required
              />
            </div>

            <button
              type="submit"
              className="bg-[#132043] text-white font-bold px-4 py-2 rounded hover:bg-blue-800"
            >
              Send
            </button>
          </form>
        </section>
      </div>
      <div className="p-8"></div>
    </div>
  );
};

export default Content6;
