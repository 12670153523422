// Import the necessary dependencies
import React from "react";
import img4 from "../../Assets/images/Picture4.jpeg";
// Define the Home component
function Content1() {
  return (
    <div id="SystemArchitecture" className="text-justify">
      <section>
        <div className="p-8">
          <div className="flex flex-col-reverse md:flex-row gap-4">
            <div>
              <h1 className="text-2xl font-bold mb-4 text-[#132043]">
                Systems Architecture
              </h1>
              <p className="mb-4 md:mb-0 md:mr-4 text-lg">
                A well-defined system architecture is crucial for any successful
                product. At SGES, we design robust, scalable architectures that
                align with your business goals and technical needs. Our services
                include:
              </p>
              <ul className="list-disc pl-5">
                <li>
                  <span className="font-bold">Architecture Definition:</span>{" "}
                  Creating a clear vision for your product, detailing
                  components, interfaces, and relationships.
                </li>
                <li className="pt-4">
                  <span className="font-bold">Technology Selection: </span>{" "}
                  Choosing the best technologies and platforms for your system.
                </li>
                <li className="pt-4">
                  <span className="font-bold">Performance Optimization: </span>{" "}
                  Designing to meet performance requirements and optimize
                  resource use.
                </li>
                <li className="pt-4">
                  <span className="font-bold">
                    Scalability and Maintainability:
                  </span>{" "}
                  Developing architectures that support future growth and
                  changes while maintaining integrity.
                </li>
              </ul>
              <h2 className="text-lg  mb-4 p-8 ">
                By focusing on these areas, we deliver efficient and adaptable
                architectures that meet evolving market demands.
              </h2>
            </div>
            <img
              src={img4}
              alt="Picture 4"
              className="w-full md:w-1/3 mb-4 md:mb-0 rounded-2xl"
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Content1;
