// Import the necessary dependencies
import React from "react";
import img from "../../Assets/images/Picture10.jpeg";
// Define the Home component
function Content4() {
  return (
    <div id="RF">
      <section>
        <div className="p-8">
          <div className="flex flex-col md:flex-row gap-4">
            <img
              src={img}
              alt="Picture 2"
              className="w-full md:w-1/3 mb-4 md:mb-0 rounded-2xl"
            />
            <div>
              <h1 className="text-2xl font-bold mb-4 c text-[#132043] pt-8">
                RF/Microwave Components/Modules
              </h1>
              <p className="mb-4 md:mb-0 md:mr-4 text-lg">
                SGES has extensive expertise in designing and developing RF and
                microwave components and modules. We use advanced design
                techniques and cutting-edge tools to provide high-performance
                solutions for various applications. Our capabilities include:
              </p>
              <ul className="list-disc pl-5 text-lg">
                <li>
                  <span className="font-bold">
                    RF/Microwave Circuit Design:
                  </span>{" "}
                  Creating high-frequency circuits for applications like
                  wireless communication, radar, and satellite systems.
                </li>
                <li className="pt-2">
                  <span className="font-bold">
                    Component Selection and Integration:
                  </span>{" "}
                  Choosing and integrating the right RF/microwave components to
                  meet performance standards.
                </li>
                <li className="pt-2">
                  <span className="font-bold">PCB Layout Optimization: </span>{" "}
                  Optimizing signal integrity and impedance matching for
                  high-frequency circuits.
                </li>
                <li className="pt-2">
                  <span className="font-bold">Prototyping and Testing: </span> :
                  Developing and testing prototypes to ensure performance and
                  reliability.
                </li>
              </ul>
              <p className="text-lg pt-2">
                We specialize in stripline technology, switches, filters, RF
                SoCs, and amplifiers, providing customized solutions tailored to
                our clients' specific needs.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Content4;
