import React, { useState } from "react";
import logo from "../../Assets/images/Picture30.jpeg"; // Importing the image
import { Link } from "react-router-dom";
import { scroller } from "react-scroll";

const scrollToElement = (sectionName) => {
  setTimeout(() => {
    scroller.scrollTo(sectionName, {
      duration: 100,
      smooth: "easeInOutQuart",
      offset: 0,
    });
  }, 100);
};

const HoverBox = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Link
      to="/Industries" // Make the entire box a clickable link
      onClick={() => scrollToElement("CN")}
    >
      <div
        className="relative w-80 h-80 bg-cover bg-center bg-opacity-70 overflow-hidden rounded-lg cursor-pointer"
        style={{
          backgroundImage: `url(${logo})`, // Use the imported image as the background
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {/* Title */}
        <div
          className={`absolute bottom-0 left-0 right-0 bg-[#132043]
          bg-opacity-50 text-center text-white font-bold text-2xl transition-transform duration-300 ease-in-out p-4 ${
            isHovered ? "translate-y-[-3rem]" : ""
          }`}
        >
          Communication and Networking
        </div>

        {/* Button */}
        {isHovered && (
          <button
            className="absolute bottom-0 left-0 w-full h-1/8 bg-[#132043]
          font-bold text-lg p-2 transition-opacity duration-300
          ease-in-out hover:bg-[#132043]"
          >
            <Link
              to="/Industries"
              style={{ color: "#ffc501" }}
              onClick={() => scrollToElement("CN")}
            >
              Explore More
            </Link>
          </button>
        )}
      </div>
    </Link>
  );
};

export default HoverBox;
