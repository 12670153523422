import React, { useRef } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import { scroller } from "react-scroll";

const ServicesDropdown = () => {
  const dropdownRef = useRef(null);
  const dropdownButtonRef = useRef(null);

  const toggleDropdown = () => {
    dropdownRef.current.style.display =
      dropdownRef.current.style.display === "block" ? "none" : "block";
    setPosition();
  };

  const setPosition = () => {
    const dropdown = dropdownRef.current;
    const button = dropdownButtonRef.current;
    if (dropdown && button) {
      const rect = button.getBoundingClientRect();
      dropdown.style.top = `${rect.bottom + window.scrollY}px`;
      dropdown.style.left = `${rect.left + window.scrollX}px`;
    }
  };

  const scrollToElement = (sectionName) => {
    setTimeout(() => {
      scroller.scrollTo(sectionName, {
        duration: 100,
        smooth: "easeInOutQuart",
        offset: 0,
      });
    }, 100);
    if (dropdownRef.current) {
      dropdownRef.current.style.display = "none";
    }
  };

  return (
    <div className="text-center">
      <div className="dropdown-content" ref={dropdownRef}>
        <MDBContainer
          class="bg-[#132043] rounded-md"
          className="absolute text-center text-md-start mt-5"
        >
          <MDBRow className="mt-3">
            <MDBCol md="3" lg="2" xl="2" className="mx-auto mb-4">
              <h5 className="text-uppercase fw-bold mb-4">
                <Link to="/Services">Conceptualization</Link>
              </h5>
              <p className="text-white">
                <Link
                  to="/Services"
                  onClick={() => scrollToElement("SystemEngineering1")}
                >
                  System Engineering
                </Link>
              </p>
              <p className="text-white">
                <Link
                  to="/Services"
                  onClick={() => scrollToElement("SystemArchitecture")}
                >
                  System Architecture
                </Link>
              </p>
            </MDBCol>
            <MDBCol md="3" lg="3" xl="3" className="mx-auto mb-4">
              <h5 className="text-uppercase fw-bold mb-4 ">
                <Link to="/hardware">Embedded Systems Design Hardware </Link>
              </h5>
              <Link to="/Hardware" onClick={() => scrollToElement("Analog")}>
                Analog/Digital & Mixed Signal Electronics
              </Link>

              <Link to="/Hardware" onClick={() => scrollToElement("PCB")}>
                High-Speed PCB Design
              </Link>

              <Link to="/Hardware" onClick={() => scrollToElement("RF")}>
                RF/Microwave Components/Modules
              </Link>

              <Link to="/Hardware" onClick={() => scrollToElement("AS")}>
                Antenna/Sensors and Array Design
              </Link>
            </MDBCol>
            <MDBCol md="3" lg="3" xl="3" className="mx-auto mb-4">
              <h5 className="text-uppercase fw-bold mb-4 ">
                <Link to="/Software">Embedded Systems design Software</Link>
              </h5>
              <p>
                <Link to="/Software" onClick={() => scrollToElement("RA")}>
                  Real-time Applications
                </Link>
              </p>
              <p>
                <Link to="/Software" onClick={() => scrollToElement("BSP")}>
                  BSP, Firmware, and Driver Development
                </Link>
              </p>
              <p>
                <Link to="/Software" onClick={() => scrollToElement("NCS")}>
                  Networking and Communication Software
                </Link>
              </p>
              <p>
                <Link to="/Software" onClick={() => scrollToElement("HPC")}>
                  High-Performance Computing
                </Link>
              </p>
            </MDBCol>

            <MDBCol md="3" lg="2" xl="2" className="mx-auto mb-4 ">
              <h5 className="text-uppercase fw-bold mb-4 ">
                <Link to="/RegulatoryCompliance">Certification&Compliance</Link>
              </h5>
              <p>
                <Link
                  to="/RegulatoryCompliance"
                  onClick={() => scrollToElement("PC")}
                >
                  Process Compliance
                </Link>
              </p>
              <p>
                <Link
                  to="/RegulatoryCompliance"
                  onClick={() => scrollToElement("PRC")}
                >
                  Product Compliance
                </Link>
              </p>
              <p>
                <Link
                  to="/RegulatoryCompliance"
                  onClick={() => scrollToElement("CS")}
                >
                  Certification Support
                </Link>
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    </div>
  );
};

export default ServicesDropdown;
