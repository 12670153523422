import React from "react";
import img from "../../Assets/images/graphic.png";

function Content1() {
  return (
    <div className='className="text-justify"'>
      <section className="p-8  rounded-2xl">
        <div className="flex justify-center items-center flex-col  ">
          {" "}
          <img src={img} alt="Picture 1" className="rounded-2xl " />
        </div>
      </section>
    </div>
  );
}

export default Content1;
