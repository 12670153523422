// Import the necessary dependencies
import React from "react";
import Navbar from "../../Components/Header/Navbar";

import "./Home.css";
import Footer from "../../Components/Footer/Footer";
import "./Content1"
import Content1 from "./Content1";
import Content2 from "./Content2";
import Content3 from "./Content3";
import Content4 from "./Content4";
import Content5 from "./Content5";
import Content6  from "./Content6";

// Define the Home component
function Home() {
  return (
    <div>
      <Navbar />
      <Content1/>
      <Content2/>
      <Content3/>
      <Content4/>
      <Content5/>
      <Content6/>
      <Footer />
    </div>
  );
};

export default Home;
