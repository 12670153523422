import React from "react";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import Industries from "./Pages/Industries/Industries";
import Resources from "./Pages/Resources/Resources";
import Conceptulization from "./Pages/Conceptualization/Conceptualization";
import Software from "./Pages/Software/Software";
import Hardware from "./Pages/Hardware/Hardware";
import RegulatoryCompliance from "./Pages/RegulatoryCompliance/RegulatoryCompliance";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },

  {
    path: "about",
    element: <About />,
  },
  {
    path: "Industries",
    element: <Industries />,
  },
  {
    path: "Resources",
    element: <Resources />,
  },
  {
    path: "Services",
    element: <Conceptulization />,
  },

  {
    path: "RegulatoryCompliance",
    element: <RegulatoryCompliance />,
  },
  {
    path: "Software",
    element: <Software />,
  },
  {
    path: "Hardware",
    element: <Hardware />,
  },
]);

const root = createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
