// Import the necessary dependencies
import React from "react";
import Navbar from "../../Components/Header/Navbar";
import Footer from "../../Components/Footer/Footer";
import { Outlet } from "react-router-dom";
import Content1 from "./Content1";
import Content2 from "./Content2";
import Content3 from "./Content3";
import Content4 from "./Content4";
import Content5 from "./Content5";

// Define the Home component
function Conceptualization() {
  return (
    <div className="text-justify">
      <Navbar />
      <Content1 />
      <Content2 />
      <Content3 />
      <Content4 />
      <Content5 />
      <Footer />
      <Outlet />
    </div>
  );
};

export default Conceptualization;
