// Import the necessary dependencies
import React from 'react';
import Navbar from '../../Components/Header/Navbar';

// Define the Home component
const About= () => {
    return (
        <div>
            <Navbar />
            <h1>I am About Page</h1>
        </div>
    );
};

export default About;