import React from "react";
import Aero from "../../Components/Card/Aero";
import Health from "../../Components/Card/Health";
import Auto from "../../Components/Card/Auto";
import Railway from "../../Components/Card/Railway";
import IOT from "../../Components/Card/IOT";
import CN from "../../Components/Card/CN";

function Content1() {
  return (
    <div className="p-8 bg-[#CACFD6] rounded-2xl">
      <h1 className="text-2xl font-bold text-[#132043] text-center mb-6">
        Industries
      </h1>
      <div className="flex flex-wrap justify-center gap-4">
        {/* Card Container */}
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 flex justify-center">
          <Aero />
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 flex justify-center">
          <Health />
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 flex justify-center">
          <Auto />
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 flex justify-center">
          <Railway />
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 flex justify-center">
          <IOT />
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 flex justify-center">
          <CN />
        </div>
      </div>
    </div>
  );
}

export default Content1;
