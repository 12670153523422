// Import the necessary dependencies
import React from "react";
import img from "../../Assets/images/Picture17.jpeg";
// Define the Home component
function Content1() {
  return (
    <div id="C&C" className="text-justify">
      <section>
        <div className="p-8 bg-[#CACFD6] rounded-2xl">
          <div className="flex justify-center items-center flex-col-reverse  lg:gap-40 md:flex-row mt-4 gap-20">
            <div>
              <h1 className="text-4xl font-bold mb-4 text-[#132043] text-left">
                Certification and Compliance
              </h1>

              <p className="mb-4 md:mb-0 lg:mr-40 text-xl pt-8 ">
                At <b>Silicon Glen Embedded Systems (SGES)</b>, we understand
                the critical importance of compliance and certification in
                today's highly regulated industries. Our dedicated team of
                experts ensures that your products meet the strictest standards,
                mitigating risks and accelerating time-to-market.
              </p>
            </div>
            <img
              src={img}
              alt="Picture 1"
              className="w-full md:w-1/3  md:mb-0 rounded-2xl h-80 mr-20"
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Content1;
