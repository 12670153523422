// Import the necessary dependencies
import React from "react";
import img from "../../Assets/images/Picture15.jpeg";
// Define the Home component
function Content1() {
  return (
    <div id="S" className="text-justify">
      <section>
        <div className="p-8 bg-[#CACFD6] rounded-2xl">
          <div className="flex flex-col-reverse md:flex-row mt-4">
            <div>
              <h1></h1>
              <h1 className="text-4xl font-bold mb-4 text-[#132043] text-left">
                Embedded Systems design Services
              </h1>

              <p className="mb-4 md:mb-0 md:mr-4 text-xl lg:mr-56 pt-10">
                At <b>Silicon Glen Embedded Systems (SGES)</b>, we specialize in
                developing robust, reliable software solutions that meet
                stringent requirements. With deep expertise in complex systems
                and a proven track record, we are committed to being your
                reliable partner for all your software development needs.
              </p>
            </div>
            <img
              src={img}
              alt="Picture 1"
              className="w-full md:w-1/3 mb-4 md:mb-0 rounded-2xl h-80 object-cover"
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Content1;
