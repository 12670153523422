// Import the necessary dependencies
import React from "react";
import img from "../../Assets/images/Picture24.jpeg";
// Define the Home component
function Content1() {
  return (
    <div className="text-justify">
      <section>
        <div className="p-8 bg-[#CACFD6] rounded-2xl">
          <div className="flex flex-col-reverse gap-40 md:flex-row mt-4">
            <div>
              <h1 className="text-4xl font-bold mb-4 text-[#132043] text-left">
                Industrial Solutions
              </h1>

              <p className="mb-4 md:mb-0 lg:mr-40 text-xl pt-8 ">
                At <b>Silicon Glen Embedded Systems (SGES)</b>, we specialize in
                delivering innovative and high-performance electronic and
                embedded system solutions to a wide range of industries. Our
                team's deep technical expertise allows us to support various
                sectors with tailored designs, development, and compliance
                services. Here's how we serve key industries:
              </p>
            </div>
            <img
              src={img}
              alt="Picture 1"
              className="w-full md:w-1/3  md:mb-0 rounded-2xl h-80 mr-20"
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Content1;
