import React from "react";
import logo from "../../Assets/images/Picture16.jpeg";
import { Link } from "react-router-dom";
import { scroller } from "react-scroll";

const scrollToElement = (sectionName) => {
  setTimeout(() => {
    scroller.scrollTo(sectionName, {
      duration: 100,
      smooth: "easeInOutQuart",
      offset: 0,
    });
  }, 100);
};

const CardFlip = () => {
  return (
    <div className="p-4 rounded-2xl ">
      <div className="flip-card" tabIndex="0">
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <div className="image-container">
              <div className="image">
                <img src={logo} alt="Logo" />
              </div>
              <div className="image-caption">
                Analog/Digital & Mixed Signal Electronics
              </div>
            </div>
          </div>
          <div className="flip-card-back">
            <ul className="list-disc pl-5 p-4 text-left">
              <li>Data Acquisition (DAQ)</li>
              <li>Audio/Video Processing</li>
              <li>Baseband Processing</li>
              <li>Discrete Electronics</li>
              <button className="bg-[#CACFD6] p-2 rounded-2xl mt-4">
                <Link
                  to="/hardware"
                  onClick={() => scrollToElement("Analog")}
                  className="text-[#132043] font-bold"
                >
                  Read more
                </Link>
              </button>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardFlip;
