// Import the necessary dependencies
import React from "react";
import img from "../../Assets/images/Picture9.jpeg";

// Define the Home component
function Content5() {
  return (
    <div id="AS">
      <section className="p-8 rounded-2xl bg-[#CACFD6]">
        <div className="p-8">
          <div className="flex flex-col md:flex-row gap-4">
            <div>
              <h1 className="text-2xl font-bold mb-4 c text-[#132043] pt-8">
                Antenna/Sensors and Array Design
              </h1>
              <p className="mb-4 md:mb-0 md:mr-4 text-lg">
                SGES provides comprehensive antenna, sensor, and array design
                services for a variety of applications, including wireless
                communication and environmental monitoring. Our expertise
                includes:
              </p>
              <ul className="list-disc pl-5 text-lg">
                <li>
                  <span className="font-bold">Antenna Design: </span> Creating
                  custom antennas for various frequency bands and applications,
                  such as cellular, Wi-Fi, GPS, and RFID.
                </li>
                <li className="pt-2">
                  <span className="font-bold">Array Design: </span> Designing
                  antenna arrays for enhanced gain, beamforming, and spatial
                  diversity.
                </li>
                <li className="pt-2">
                  <span className="font-bold">Electromagnetic Simulation:</span>{" "}
                  Using advanced tools to optimize antenna and sensor
                  performance.
                </li>
                <li className="pt-2">
                  <span className="font-bold">
                    Prototype Development and Testing:
                  </span>{" "}
                  Building and testing prototypes to validate design parameters.
                </li>
              </ul>
              <p className="mb-4 md:mb-0 md:mr-4 text-lg">
                We deliver tailored solutions to meet your specific needs,
                ensuring optimal performance and reliability.
              </p>
            </div>
            <img
              src={img}
              alt="Picture 2"
              className="w-full md:w-1/3 mb-4 md:mb-0 rounded-2xl h-96 object-cover"
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Content5;
