import React, { useRef } from "react";
import "./IndustriesDropdown.css";
import { scroller } from "react-scroll";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";

const Dropdown = () => {
  const dropdownRef = useRef(null);
  const dropdownButtonRef = useRef(null);

  const toggleDropdown = () => {
    dropdownRef.current.style.display =
      dropdownRef.current.style.display === "block" ? "none" : "block";
    setPosition();
  };

  const setPosition = () => {
    const dropdown = dropdownRef.current;
    const button = dropdownButtonRef.current;
    if (dropdown && button) {
      const rect = button.getBoundingClientRect();
      dropdown.style.top = `${rect.bottom + window.scrollY}px`;
      dropdown.style.left = `${rect.left + window.scrollX}px`;
    }
  };
  const scrollToElement = (sectionName) => {
    setTimeout(() => {
      scroller.scrollTo(sectionName, {
        duration: 100,
        smooth: "easeInOutQuart",
        offset: 0,
      });
      if (dropdownRef.current) {
        dropdownRef.current.style.display = "none";
      }
    }, 100);
  };

  return (
    <div>
      <div className="dropdown-content" ref={dropdownRef}>
        <MDBContainer
          class="bg-[#132043] rounded-md"
          className="absolute text-center text-md-start mt-5"
        >
          <MDBRow className="mt-3">
            <MDBCol md="12" lg="12" xl="12" className="mx-auto mb-4">
              <div className="industries-column">
                <h6
                  className="text-uppercase fw-bold mb-4"
                  style={{ color: "#ffc501" }}
                >
                  Industries
                </h6>
                <p>
                  <Link
                    to="/Industries"
                    onClick={() => scrollToElement("Aero")}
                  >
                    Aerospace and Defence Industry
                  </Link>
                </p>
                <p>
                  <Link
                    to="/Industries"
                    onClick={() => scrollToElement("Health")}
                  >
                    Healthcare Industry
                  </Link>
                </p>
                <p>
                  <Link
                    to="/Industries"
                    onClick={() => scrollToElement("Auto")}
                  >
                    Automotive Industry
                  </Link>
                </p>
                <p>
                  <Link
                    to="/Industries"
                    onClick={() => scrollToElement("Railway")}
                  >
                    Railway Industry
                  </Link>
                </p>
                <p>
                  <Link to="/Industries" onClick={() => scrollToElement("IOT")}>
                    IoT and Connected Devices
                  </Link>
                </p>
                <p>
                  <Link
                    to="/Industries"
                    onClick={() => scrollToElement("CN")}
                  >
                    Communication and Networking
                  </Link>
                </p>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    </div>
  );
};

export default Dropdown;
