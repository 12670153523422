import React from "react";
import img from "../../Assets/images/Picture31.jpeg";
import { Link } from "react-router-dom";
import { scroller } from "react-scroll";

const scrollToElement = (sectionName) => {
  setTimeout(() => {
    scroller.scrollTo(sectionName, {
      duration: 100,
      smooth: "easeInOutQuart",
      offset: 0,
    });
  }, 100);
};

function Content1() {
  return (
    <div className='className="text-justify"'>
      <section className="p-8 bg-[#CACFD6] rounded-2xl">
        <div className="flex justify-center items-center flex-col-reverse  lg:gap-40 lg:ml-16 lg:mr-16 md:flex-row mt-4 gap-28 ">
          <div>
            <h1 className="text-4xl font-bold text-[#132043] text-left lg:mr-40">
              High-Integrity Embedded Systems for{" "}
              <span>Mission-Critical Projects</span>
            </h1>

            <p className="mb-4 md:mb-0 lg:mr-40 text-xl pt-8 ">
              Delivering innovative, reliable, mission-critical systems and
              high-performance networking solutions across diverse industries,
              backed by decades of experience.
            </p>
            <Link
              to="/"
              style={{ color: "#ffc501" }}
              onClick={() => scrollToElement("Contact")}
            >
              <button className="text-[#CACFD6] p-2 rounded-2xl font-bold bg-[#132043] hover:text-[#132043]">
                Get in touch
              </button>
            </Link>
          </div>
          <img
            src={img}
            alt="Picture 1"
            className="w-full md:w-1/3  md:mb-0 rounded-2xl h-80 "
          />
        </div>
      </section>
    </div>
  );
}

export default Content1;
