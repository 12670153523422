// Import the necessary dependencies
import React from "react";
import img from "../../Assets/images/Picture22.jpeg";
// Define the Home component

function Content4() {
  return (
    <div id="HPC">
      <section>
        <div className="p-8">
          <div className="flex flex-col-reverse md:flex-row gap-4">
            <div>
              <h1 className="text-2xl font-bold mb-4 text-[#132043]">
                High-Performance Computing
              </h1>
              <p className="mb-4 md:mb-0 md:mr-4 text-lg">
                SGES develops software solutions that maximize modern computing
                architecture performance, handling demanding workloads
                efficiently. Our services include:
              </p>
              <ul className="list-disc pl-5">
                <li>
                  <span className="font-bold">Performance Optimization:</span>{" "}
                  Eliminating bottlenecks for optimal results.
                </li>
                <li className="pt-4">
                  <span className="font-bold">Parallel Programming: </span>{" "}
                  Utilizing multi-core and multi-processor systems.
                </li>
                <li className="pt-4">
                  <span className="font-bold">Accelerator Optimization:</span>{" "}
                  Using GPUs and FPGAs to accelerate computations.
                </li>
                <li className="pt-4">
                  <span className="font-bold">Data-intensive Computing:</span>{" "}
                  Efficiently managing large datasets.
                </li>
                <li className="pt-4">
                  <span className="font-bold">Network Optimization: </span>{" "}
                  Enhancing network communication for high-performance
                  computing.
                </li>
              </ul>
              <h2 className="text-lg  mb-4 p-8 ">
                Our expertise in hardware and advanced software techniques
                ensures high-performance solutions with exceptional results.
              </h2>
            </div>
            <img
              src={img}
              alt="Picture 4"
              className="w-full md:w-1/3 mb-4 md:mb-0 rounded-2xl"
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Content4;
