import React from "react";

// Define the Content1 component
function Content1() {
  return (
    <div className="text-justify">
      <section>
        <div className="p-8 bg-[#CACFD6] rounded-2xl">
          <h1 className="text-2xl font-bold mb-4 text-center">
            <span className="inline-block rounded-full px-3 py-1 text-2xl font-bold mr-2 mb-2">
              Software Development Process
            </span>
          </h1>
          <h2 className="text-lg mb-4 text-center text-[#132043]">
            At SGES, our software development process ensures high-quality,
            reliable, and efficient solutions. We focus on collaboration,
            efficiency, and continuous improvement through the following stages:
          </h2>

          {/* Flex container for responsive layout */}
          <div className="flex flex-col md:flex-row gap-10 justify-center">
            <div className="flex-1 max-w-xs w-full mx-auto rounded overflow-hidden shadow-lg flex flex-col">
              <div className="px-6 py-4 flex-grow">
                <div className="font-bold text-xl mb-2 text-[#132043]">
                  Requirements Gathering and Analysis:
                </div>
                <p className="text-base pb-2">
                  Collaborating closely with clients to define software
                  requirements.
                </p>
              </div>
            </div>

            <div className="flex-1 max-w-xs w-full mx-auto rounded overflow-hidden shadow-lg flex flex-col">
              <div className="px-6 py-4 flex-grow">
                <div className="font-bold text-xl mb-2 text-[#132043]">
                  Software Design and Architecture:
                </div>
                <p className="text-base pb-2">
                  Creating scalable and maintainable architecture using
                  methodologies like UML.
                </p>
              </div>
            </div>

            <div className="flex-1 max-w-xs w-full mx-auto rounded overflow-hidden shadow-lg flex flex-col">
              <div className="px-6 py-4 flex-grow">
                <div className="font-bold text-xl mb-2 text-[#132043]">
                  Coding and Implementation:
                </div>
                <p className="text-base">
                  Writing clean, efficient, well-documented code following best
                  practices.
                </p>
              </div>
            </div>
          </div>

          {/* Second row of flex items */}
          <div className="flex flex-col md:flex-row gap-10 pt-10 justify-center">
            <div className="flex-1 max-w-xs w-full mx-auto rounded overflow-hidden shadow-lg flex flex-col">
              <div className="px-6 py-4 flex-grow">
                <div className="font-bold text-xl mb-2 text-[#132043]">
                  Testing and Verification:
                </div>
                <p className="text-base pb-2">
                  Rigorous testing to ensure software quality and reliability.
                </p>
              </div>
            </div>

            <div className="flex-1 max-w-xs w-full mx-auto rounded overflow-hidden shadow-lg flex flex-col">
              <div className="px-6 py-4 flex-grow">
                <div className="font-bold text-xl mb-2 text-[#132043]">
                  Integration and Deployment:
                </div>
                <p className="text-base pb-2">
                  Integrating components and deploying the software to the
                  target environment.
                </p>
              </div>
            </div>

            <div className="flex-1 max-w-xs w-full mx-auto rounded overflow-hidden shadow-lg flex flex-col">
              <div className="px-6 py-4 flex-grow">
                <div className="font-bold text-xl mb-2 text-[#132043]">
                  Maintenance and Support:
                </div>
                <p className="text-base">
                  Providing ongoing support, bug fixes, and enhancements.
                </p>
              </div>
            </div>
          </div>

          <h2 className="text-lg mb-4 p-8 text-[#132043] text-center">
            We adapt our approach to each project, combining Agile and Waterfall
            methodologies to optimize development.
          </h2>
        </div>
      </section>
    </div>
  );
}

export default Content1;
