// Import the necessary dependencies
import React from "react";
import img from "../../Assets/images/Picture1.jpg";
// Define the Home component
function Content1() {
  return (
    <div id="Conceptualization" className="text-justify">
      <section>
        <div className="p-8 bg-[#CACFD6] rounded-2xl ">
          <div className="flex flex-col-reverse md:flex-row mt-4">
            <div>
              <h1 className="text-4xl font-bold mb-4 text-[#132043] text-left ">
                Conceptualization Services
              </h1>

              <p className="mb-4 md:mb-0 md:mr-4 text-lg lg:pr-44 lg:pt-6">
                A strong concept is key to a successful product. At{" "}
                <b>Silicon Glen Embedded Systems (SGES)</b>, we focus on the
                critical early stages of product development. Our
                conceptualization services transform your vision into a tangible
                reality by combining systems engineering and architecture to
                analyze requirements, define boundaries, and create a solid
                blueprint. This approach reduces risks, optimizes resources, and
                ensures efficient development and deployment.
              </p>
            </div>
            <img
              src={img}
              alt="Picture 1"
              className="w-full md:w-1/3 mb-4 md:mb-0 rounded-2xl"
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Content1;
