// Import the necessary dependencies
import React from "react";
import img from "../../Assets/images/Picture6.jpeg";
// Define the Home component
function Content3() {
  return (
    <div id="PCB">


      <section className="p-8 rounded-2xl bg-[#CACFD6]">
        <div className="p-8">
          <div className="flex flex-col-reverse md:flex-row gap-4">
            <div>
              <h1 className="text-2xl font-bold mb-4 c text-[#132043] pt-8">
                High-Speed PCB Design
              </h1>
              <p className="mb-4 md:mb-0 md:mr-4 text-lg">
                High-speed PCB design is essential for optimal performance and
                reliability. At SGES, we have the expertise and tools to provide
                advanced PCB solutions, including:
              </p>
              <ul className="list-disc pl-5 text-lg">
                <li>
                  <span className="font-bold">Complex PCB Layout:</span>{" "}
                  Designing intricate PCBs to support high-speed signals and
                  minimize crosstalk.
                </li>
                <li className="pt-2">
                  <span className="font-bold">Signal Integrity Analysis:</span>{" "}
                  Optimizing signal transmission through impedance matching and
                  efficient power distribution.
                </li>
                <li className="pt-2">
                  <span className="font-bold">Thermal Management:</span>{" "}
                  Designing PCBs to effectively dissipate heat and maintain
                  performance.
                </li>
                <li className="pt-2">
                  <span className="font-bold">Multilayer PCB Design:</span>{" "}
                  Creating complex multilayer PCBs to accommodate high-speed
                  components and routing.
                </li>
              </ul>
              <p className="mb-4 md:mb-0 md:mr-4 text-lg">
                We work with a variety of processors, such as PowerPC QorIQ,
                Intel Xeon, AMD, ARM Cortex, and Zynq, to deliver
                high-performance PCB designs tailored to your specific needs.
              </p>
            </div>
            <img
              src={img}
              alt="Picture 2"
              className="w-full md:w-1/3 mb-4 md:mb-0 rounded-2xl"
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Content3;
