// Import the necessary dependencies
import React from "react";

// Define the Home component
function Content2() {
  return (
    <div className="text-justify">
      <section>
        <div className="p-8 rounded-2xl">
          <h1 className="text-center">
            <span className=" text-[#132043] text-2xl font-bold ">
              Core Software Capabilities
            </span>
          </h1>
          <h2 className="text-center">
            <span className="text-[#132043]  text-2xl font-bold mb-2">
              Mission and Safety Critical Software
            </span>
          </h2>
          <h1 className="text-lg mb-4 text-[#132043] ">
            SGES specializes in software for mission and safety-critical systems
            requiring top reliability and performance. Our expertise includes:
          </h1>
          <div className="flex flex-col-reverse md:flex-row gap-20 justify-center items-stretch">
            <div className="flex-1 max-w-sm rounded  overflow-hidden shadow-lg  flex flex-col bg-[#CACFD6]">
              <div className="px-6 py-4 flex-grow">
                <div className=" font-bold text-xl mb-2 text-[#132043]">
                  UML-based Development:
                </div>
                <p className="text text-base pb-2 pt-2">
                  Designing complex software architectures using Unified
                  Modeling Language (UML).
                </p>
              </div>
            </div>
            <div className="flex-1 max-w-sm rounded overflow-hidden shadow-lg  flex flex-col bg-[#CACFD6]">
              <div className="px-6 py-4 flex-grow">
                <div className="font-bold text-xl mb-2 text-[#132043] ">
                  OpenGL-SC:
                </div>
                <p className=" text-base pb-2 pt-2">
                  Creating graphics-intensive applications for safety-critical
                  environments.
                </p>
              </div>
            </div>
            <div className="flex-1 max-w-sm rounded overflow-hidden shadow-lg flex flex-col bg-[#CACFD6]">
              <div className="px-6 py-4 flex-grow">
                <div className="font-bold text-xl mb-2 text-[#132043]">
                  DO-178C Compliance:
                </div>
                <p className=" text-base pt-2">
                  Meeting strict aviation software standards. Design Structure
                  Matrix
                </p>
              </div>
            </div>
          </div>
          <h1 className="text-lg mt-4 text-[#132043] ">
            We have a proven track record in aerospace, defense, and medical
            industries, where safety and reliability are essential.
          </h1>
        </div>
      </section>
    </div>
  );
}

export default Content2;
