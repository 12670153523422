// Import the necessary dependencies
import React from "react";
import img from "../../Assets/images/Picture21.jpeg";

// Define the Home component
function Content3() {
  return (
    <div id="NCS">
      <section className="text-justify bg-[#CACFD6] rounded-2xl">
        <div className="p-8 ">
          <div className="flex flex-col md:flex-row gap-4">
            <img
              src={img}
              alt="Picture 2"
              className="w-full md:w-1/3 mb-4 md:mb-0 rounded-2xl"
            />
            <div>
              <h1 className="text-2xl font-bold mb-4 c text-[#132043]">
                Networking and Communication Software
              </h1>
              <p className="mb-4 md:mb-0 md:mr-4 text-lg">
                At SGES, we have a deep understanding of network protocols and
                architectures, allowing us to develop robust communication
                solutions across various technologies:
              </p>

              <ul className="list-disc pl-5 ">
                <li>
                  <span className="font-bold ">
                    Network Protocol Implementation:
                  </span>{" "}
                  Custom protocols and stacks for specific needs.
                </li>
                <li className="pt-4">
                  <span className="font-bold">IP Networking: </span> Designing
                  and implementing TCP/IP applications and services.
                </li>
                <li className="pt-4">
                  <span className="font-bold">
                    High-Performance Networking:
                  </span>{" "}
                  Enhancing performance with technologies like DPDK, SR-IOV, and
                  AVX-512.
                </li>
                <li className="pt-4">
                  <span className="font-bold">Virtualized Networking: </span>
                  Developing functions for virtualization environments.
                </li>
                <li className="pt-4">
                  <span className="font-bold">Network Management: </span>
                  Creating tools for monitoring and managing networks.
                </li>
              </ul>
              <p className="mb-4 md:mb-0 md:mr-4 text-lg">
                Our strong networking expertise ensures innovative and reliable
                solutions for your network infrastructure.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Content3;
