import React from 'react';
import Navbar from '../../Components/Header/Navbar';
import Footer from '../../Components/Footer/Footer';    
import Content1 from './Content1';
import Content2 from './Content02';
import Content3 from "./Content03";
import Content4 from "./Content04";
import Content5 from "./Content05";
import Content6 from "./Content06";  

const Hardware= () => {
    return (
      <div className="text-justify">
        <Navbar />
        <Content1 />
        <Content2 />
        <Content3 />
        <Content4 />
        <Content5 />
        <Content6 />
        <Footer />
      </div>
    );
};

export default Hardware;